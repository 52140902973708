body {
  background-color: #fafafa;
}

.optionImage {
  max-height: 150px;
  max-width: 180px;
  display: block;
}

.correctButton {
  background-color: #52c41a;
  border-color: #52c41a;
  color: white;
}

.correctButton:hover, .correctButton:focus {
  background-color: #73d13d;
  border-color: #73d13d;
}

.imageContainer {
  position: relative;
  display: inline-block;
}
.imageContainer .overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
}

.imageContainer:hover .overlay {
  display: block;
}

.inputHeader {
  padding-bottom: 10px;
  display: block;
}

#global_state {
  margin-top: 15px;
}

.nextButton {
  float: right;
}

.inputContainer {
  position: relative;
  padding-right: 30px;
}

.addImageButton {
  position: absolute;
  right: 0px;
  top: 0px;
}

.removeAnsButton {
  float: right;
}

.titleInput {
  display: inline-block;
  padding-bottom: 10px;
}

.removeQuestion {
  float: right;
}

.quesAnsDividera {
  margin-top: 10px;
}
