.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: 16px;
  margin-top: 50px;
}

.fullPage {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.displayImage {
  max-width: 180px;
  margin-top: 10px;
}

.headerContainer {
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.06);
  padding-bottom: 10px;
  position: sticky;
  top: 0px;
  z-index: 50;
  background-color: white;
}

@media (max-width: 480px) {
  .headerContainer {
    padding-bottom: 0px;
  }
}
