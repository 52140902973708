.w100 {
  width: 100%;
}

.footerButton {
  height: 48px;
  margin: 24px;
  font-size: 16px;
  font-weight: "600";
  background-image: linear-gradient(to top, #c748a4, #962377);
  color: #fff;
  border-radius: 35px;
}

.resultCardBorder {
  border: 1px dotted #962377;
  border-radius: 5px;
}

.validresult {
  color: black;
}

.invalidresult {
  color: #e62036;
  text-decoration: underline;
}

.question-text {
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.fw800 {
  font-weight: 800;
}

.fwsb .fw600 {
  font-weight: 600;
}

.fsz48 {
  font-size: 48px;
}

.fsz32 {
  font-size: 32px;
}

.fsz24 {
  font-size: 24px;
}

.fsz {
  font-size: 20px;
}

.fsz15 {
  font-size: 15px;
}

.ml16 {
  margin-left: 16px;
}

.mr16 {
  margin-right: 16px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt5 {
  margin-top: 5px;
}

.pt18 {
  padding-top: 18px;
}

.pb10 {
  padding-bottom: 10px;
}

.p24 {
  padding: 24px;
}

.cursorpointer {
  cursor: pointer;
}

.breakword {
  word-wrap: break-word;
}

.col-text-blue {
  color: #2c67ef;
}

.resultCardColor {
  background-color: #fbf9ff;
}

.text-align-center {
  text-align: center;
}
