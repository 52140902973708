.assessmentTitle {
  padding-left: 8px;
}

.report-container {
  width: 100%;
  background-color: #1b44fb;
  background-image: linear-gradient(to bottom, #3a79fd, #1b44fb);
  padding: 30px 10px 10px;
  margin: 0 auto;
  max-width: 360px;
}

.det-label {
  font-size: 11px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #a9cdff;
}
.bold-font {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  font-style: normal;
  display: block;
  line-height: 24px;
}
.common-box {
  width: 100%;
}
.thin-font {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  color: #ffffff;
  display: block;
  font-weight: 400;
}
.score-box {
  width: 95px;
  height: 95px;
  text-align: center;
  padding-top: 30px;
  line-height: normal;
  border-radius: 50%;
  background-color: #2663ea;
  border: solid 2px #a9cdff;
  position: absolute;
  right: 9px;
  top: 10px;
}
.score-val {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.score-val .thin-font em {
  font-size: 32px;
  font-style: normal;
}
.score-val .thin-font {
  display: block;
  line-height: 15px;
}
.repeated-row {
  display: inline-block;
  width: 100%;
  padding: 0 9px;
}
.repeated-row .common-box {
  float: left;
  width: 50%;
}

.repeated-row .common-box:nth-child(2n) {
  padding-left: 15px;
  position: relative;
}
.repeated-row .common-box:nth-child(2n)::before {
  content: "";
  height: 30px;
  border-left: 1px solid #2a67ef;
  position: absolute;
  left: 0;
  top: 10px;
}
.tmargin10 {
  margin-top: 10px;
}
