.question-card {
  background-color: #fff;
  border-radius: 12px;
  margin-top: 10px;
  padding: 50px 15px 15px;
  position: relative;
}
.number-span {
  font-size: 12px;
  line-height: normal;
  color: #6f6f6f;
}
.question-card .q-number {
  position: absolute;
  left: 15px;
  top: 20px;
}
.question-card .mark-number {
  position: absolute;
  right: 15px;
  top: 20px;
}
.q-type {
  border-radius: 8px;
  background-color: #ffeaea;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  padding: 3px 10px 3px 27px;
  line-height: normal;
}
.question-card .q-type {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 16px;
}

.incorrect-q-type {
  background-color: #ffeaea;
  color: #f93030;
}

.correct-q-type {
  background-color: #f1fff0;
}

.incorrect-q-type .highligh-text {
  color: #f93030;
}

.correct-q-type .highligh-text {
  color: #45d84c;
}

h2.question-text {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  color: #333333;
  margin: 0;
}
.question-card .q-type .ansresult {
  position: absolute;
  top: 3px;
  left: 7px;
  max-width: 16px;
  font-size: 16px;
}
