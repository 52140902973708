ul.thumbnail-images {
  margin: 0;
  padding: 0;
}

.student-detail {
  width: 100%;
  padding: 0 120px 0 8px;
  position: relative;
}

.thumbnail-images {
  display: inline-block;
  width: 100%;
}
.thumbnail-images li {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin: 10px 10px 0 0;
  list-style: none;
  float: left;
}
.thumbnail-images li img,.thumbnail {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.answer-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.answer-list > li {
  position: relative;
  border-radius: 8px;
  border: 1px solid #aaa;
  padding: 12px 12px 12px 40px;
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 0.35px;
  color: #3f3f3f;
}
.answer-list > li::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #aaa;
  position: absolute;
  left: 15px;
  top: 15px;
}
.answer-list > li.student-answer::after {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #aaa;
  position: absolute;
  left: 18px;
  top: 18px;
}
.answer-list > li.wrong-answer {
  border-color: #f93030;
}
.answer-list > li.correct-answer {
  margin-top: 20px;
  border-color: #45d84c;
}
.answer-list > li.correct-answer .tick-sign {
  position: absolute;
  right: 15px;
  width: 23px;
  top: -12px;
  height: 23px;
  z-index: 1;
}
.answer-list > li.correct-answer .tick-sign img {
  width: 23px;
  height: 23px;
}

.tickSignIcon {
  font-size: 23px;
  color: #45d84c;
  background-color: white;
}
